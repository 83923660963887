$.fn.selectRange = function(start, end) {
    if(!end) end = start;
    return this.each(function() {
        if (this.setSelectionRange) {
            this.focus();
            this.setSelectionRange(start, end);
        } else if (this.createTextRange) {
            var range = this.createTextRange();
            range.collapse(true);
            range.moveEnd('character', end);
            range.moveStart('character', start);
            range.select();
        }
    });
};

$(function () {

    $('#content').css('min-height', $(window).height() - $('#header').height() - $('#footer').height() - 20);

    /**
     * get list of doctors
     */
    if (typeof doctorsPage != 'undefined') {
        getDoctors(doctorsPage);

        /**
         * Load doctors. And update them every 50000
         */
        setInterval(function(){getDoctors(doctorsPage)}, 50000);
    }

    if (typeof possibleConnectionsPage != 'undefined' && typeof isSidebar != 'undefined') {
        getPossibleConnections(possibleConnectionsPage, isSidebar);

        /**
         * Load possible connections. And update them every 50000
         */
        setInterval(function(){getPossibleConnections(possibleConnectionsPage, isSidebar)}, 50000);
    }

    initAutocompleteForMessageRecipient('recipient');
    initAutocompleteForFindFriends();
    initAutocompleteForSearch();

    addAltAttributeToImages();
    initAgepicker();

    $('[data-toggle="tooltip"]').tooltip();
    initPopover();
    $("#choosePossibleConnectionsCountry").chosen();
    $("#chooseDoctorsCountry").chosen();
    $("#medicalSpecialization").chosen();

    initIcheck();

    $('.post-text').linkify({
        target: "_blank"
    });

    $('.post-answer__text').linkify({
        target: "_blank"
    });


    initTagIt();

    initTooltip();

    var hash = window.location.hash;
    if (hash != '') {
        $('[href=' + hash + ']').click();
    }

    if ($('#google-recaptcha').length > 0) {
        setTimeout(function () {
            initGoogleRecaptcha();
        }, 300);
    }
});

function initGoogleRecaptcha() {
    return grecaptcha.render('google-recaptcha', {
        'sitekey': googleRCKey
    });
}

function initPopover() {
    $('[data-toggle="popover"]').popover();
}
/**
 * Itit icheck plugin
 */
function initIcheck() {
    $('input[type="checkbox"]').iCheck({
        checkboxClass: 'icheckbox_minimal-green',
    });
}
/**
 * itin tag-it plugin
 */
function initTagIt() {
    if ($.fn.tagit) {
        $("#languages").tagit({
            placeholderText: 'Languages/dialects spoken'
        });
    }
}

/**
 * Show disable content copying message
 */
function disableContentCopying(){
    swal("Copy protected content", "Copy protected content. Please check our copyright notice at the bottom of the page.");
};

/**
 * show connect form
 */
$(document).on('click', '.connect-to-user', function () {
    $('.connect-form').slideDown();
});

/**
 * send connect invitation with message
 */

$('.connect-form form').on('submit', function (e) {
    e.preventDefault();
    var data =  {
        welcome_message : $(this).find('textarea').val(),
        recipient_id : $(this).parent().attr('data-target-user')
    };
    loadAsync('profile/connect', data);
});

/**
 * hide connect form
 */

function hideConnectButton() {
    $('.connect-container').fadeOut();
};


/**
 * send message to the user
 */
$('#message-form').on('submit', function(e){
    e.preventDefault();
    var $this = $(this);
    var data = $this.serialize();
    var recipientInput = $this.find('#recipient_id');
    var subjectInput = $this.find('#subject');
    var messageInput = $this.find('#body');

    if (recipientInput.val() == ''){
        error = 'Please choose at least one recipient';
        if (recipientInput.parents('.form-group').find('.help-block').length == 0 ) {
            recipientInput.parents('.form-group').addClass('has-error').find('input[name=recipient]').after('<span class="help-block"><strong>' + error + '</strong></span>');
        }
    }
    if (subjectInput.val() == ''){
        error = 'Please enter the subject';
        if (subjectInput.parents('.form-group').find('.help-block').length == 0 ) {
            subjectInput.parents('.form-group').addClass('has-error').find('input[name=subject]').after('<span class="help-block"><strong>' + error + '</strong></span>');
        }
    }
    if (messageInput.val() == ''){
        error = 'Please enter the message body';
        if (messageInput.parents('.form-group').find('.help-block').length == 0 ) {
            messageInput.parents('.form-group').addClass('has-error').find('textarea[name=body]').after('<span class="help-block"><strong>' + error + '</strong></span>');
        }
    }
    if (error){
        return false;
    }
    loadAsync('messages/send', data);
});

/**
 * remove user messages
 */
function removeMessages(type){
    var messages = {};
    var selectedMessages = $('#' + type).find("input[name=delete]:checked");
    $.each(selectedMessages, function (n, message) {
        messages[n] = message.value;
    });
    messages['messages_type'] = type;
    swal({
            title: "Are you sure?",
            text: "Do you really want to remove messages?",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: "Yes, remove it!",
            cancelButtonText: "No, cancel!",
            closeOnConfirm: false,
            closeOnCancel: false
        },
        function(isConfirm){
            if (isConfirm) {
                loadAsync('messages/remove', messages);
            }
        });
};

/**
 * check all messages
 * @param type
 */
$(document).on('ifChecked', '.set-all-checked', function(event){
    var $this = $(this);
    var $target = $($this.attr('data-target'));
    $target.find('[type=checkbox]').each(function() {
        $(this).iCheck('check');
    });
});

/**
 * uncheck all messages
 * @param type
 */
$(document).on('ifUnchecked', '.set-all-checked', function(event){
    var $this = $(this);
    var $target = $($this.attr('data-target'));
    $target.find('[type=checkbox]').each(function() {
        $(this).iCheck('uncheck');
    });
});

/**
 * open reply modal window
 */
function replyMsg(msg_id, is_outbox){
    var data = {
        msg_id : msg_id,
        is_outbox : is_outbox
    };

    loadAsync('messages/reply', data);
};

/**
 * get doctors list
 * @param page
 */

function getDoctors(page){
    page = parseInt(page) > 0 ? parseInt(page) : 0;
    loadAsync('categories/doctors', {proType : 'medical', page: page, country : $("#chooseDoctorsCountry option:selected").val()});
};



/**
 * get user possible connections
 * @param page
 */
function getPossibleConnections(page, isSidebar){
    page = parseInt(page) > 0 ? parseInt(page) : 0;
    if(isSidebar) {
        loadAsync('profile/possible', {
            isSidebar: isSidebar,
            page: page,
            country: $("#choosePossibleConnectionsCountry option:selected").val()
        });

    } else {
        loadAsync('home/possible', {
            isSidebar: isSidebar,
            page: page,
            country: $("#choosePossibleConnectionsCountry option:selected").val()
        });
    }
};
/**
 * send connect invitation to user without message
 */
function connect(recipient_id){
    var data =  {
        recipient_id : recipient_id
    };
    loadAsync('profile/connectWithoutMessage', data);
};

/**
 * display doctors according to the selected country
 */
$('#chooseDoctorsCountry').on('change', function(page){
    page = parseInt(page) > 0 ? parseInt(page) : 0;
    getDoctors(page);
});

/**
 * display possible connections according to the selected country
 */
$('#choosePossibleConnectionsCountry').on('change', function(page){
    page = parseInt(page) > 0 ? parseInt(page) : 0;
    getPossibleConnections(page, isSidebar);
});


/**
 * get user connections and init autocomplete
 */
function initAutocompleteForMessageRecipient(){
    $("#recipient").autocomplete({
        valueKey: 'title',
        source:[
            {
                url: basePath + "messages/findRecipient",
                type:'remote',
                getTitle:function(item){
                    return item['title']
                },
            },

        ],
        minLength: 2,
    }).on('selected.xdsoft',function(e,datum){
        $('#recipient_id').val(datum.user_id);
    });
};

/**
 * init datepicker for profile birth date field
 */
$('#birthDate').datepicker({
    format: 'yyyy-mm-dd',
});

// $(document).on('keyup keydown', '#searchInput', function () {
//     // $('#searchInput').autocomplete('setSource', {});
//     $(window).trigger('update.xdsoft');
// });
/**
 * init autocomplete for find friends form
 */
function initAutocompleteForFindFriends(){
    $("#findFriendsField").autocomplete({
        highlight : false,
        valueKey: 'title',
        // titleKey: 'title',
        source:[
            {
                url: basePath + "/home/possibleConnections?query=%QUERY%",
                type:'remote',
            },

        ],
        // filter: function ( items,query,source ){
        //     var results = [],value = '';
        //     for(var i in items){
        //         value = items[i];
        //         if( this.valid(value,query)  ){
        //             results.push(items[i]);
        //         }
        //     }
        //     return results;
        // },
        // valid: function ( value,query ){
        //     return value.toLowerCase().indexOf(query.toLowerCase())!=-1;
        // },
        getValue:function(item){
            return item.split('<br>')[0];
        },
        showHint: false,
        appendMethod:'replace',
        timeoutSearch: 300,
        timeoutUpdate: 0,
        minLength: 2,
        limit: 10
        // getTitle:function(item){
        //     return item;
        // },
        // render: function( item,source,pid,query ){
        //     var value = item.split('<br>')[0];
        //         title = item;
        //     return '<div '+(value==query?'class="active"':'')+
        //         ' data-value="'+
        //         encodeURIComponent(value)+'">'+
        //         title+
        //         '</div>';
        // }
    }).on('selected.xdsoft',function(e,datum){
        var data = {
            name : datum
        };
        loadAsync('profile/openUser', data);
    });
};

/**
 * submit message reply
 */
$(document).on('submit', '#reply-form',  function(e){
    e.preventDefault();
    var data = $(this).serialize();
    loadAsync('messages/saveReply', data);
});

/**
 * get subcategories for parent category
 */
$('#parentCategorySelect').on('change', function(){
    $('#subSubcategoriesSelect').addClass('hidden');
    $('#subSubcategoriesSelect').parent().hide();
    $('#subSubcategoriesSelect [value=""]').attr('selected', true);
    var data = {
        path : $("#parentCategorySelect option:selected").val(),
        select_id : 'subcategoriesSelect',
        check : true
    };
    loadAsync('categories/getSubcategories', data);
});

/**
 * get subcaregories for subcategory
 */
$('#subcategoriesSelect').on('change', function(){
    var data = {
        path : $("#subcategoriesSelect option:selected").val(),
        select_id : 'subSubcategoriesSelect'
    };
    loadAsync('categories/getSubcategories', data);
});

/**
 * load subcategories into dropdown list
 * @param data
 */
function loadSubcategories(data){
    if(data.subcategories.length != 0) {
        $('#' + data.select_id).empty();
        $('#' + data.select_id).removeClass('hidden');
        $('#' + data.select_id).parent().show();
        $('#' + data.select_id).append('<option value="">Select category</option>');
        $.each(data.subcategories, function (key, value) {
            $('#' + data.select_id).append('<option value="' + key + '">' + value + '</option>');
        });

        initAgepicker();
        initIcheck();
    }
};

/**
 * save posts
 */
$('#postsForm').on('submit', function(e){
    e.preventDefault();
    if ($(this).find('#postsSubmit').hasClass('js-store-quest-post')){
        $('#ask-your-question-modal').modal('hide');
        $('#comment-modal').find('.modal__close').addClass('postsClose');
        $('#comment-modal').modal('show');
        return false;
    }
    var data = $(this).serialize();

    $(this).find('#postsSubmit').attr('disabled', true).removeClass('btn--green-gradient').addClass('saving').text('Posting...');
    loadAsync('posts/save', data);
});

/**
 * initialize CKEditor in post form
 */
if($('textarea').is('#quecontent')) {
    tinymce.init({
        selector:'textarea#quecontent',
        content_css : "/css/custom_content.css",
        mode : "exact",
        plugins: 'image, jbimages, paste, placeholder',
        menubar: false,
        toolbar: 'link jbimages | bold italic underline strikethrough | alignjustify | undo redo',
        relative_urls : false,
        textarea : false,
        height : 100,
        elements : 'quecontent',
        automatic_uploads: false,
        images_upload_url : '/posts/uploadImages',
        force_p_newlines : false,
        force_br_newlines : true,
        forced_root_block : false,
        init_instance_callback: function (editor) {
            editor.on('change', function (e) {
                $('#quecontent').val(tinymce.get('quecontent').getContent());
                checkPostFilling();
            });
        },
        paste_as_text: true,
        statusbar : false,
        paste_preprocess : function(pl, o) {
            o.content = o.content.replace(/\<br\>\<br\>/gi, '<br/>');
        }
    });
    //CKEDITOR.replace('quecontent');
    //CKEDITOR.config.height = 150;
};

/**
 * transfers data in a modal window from post textarea
 */
function fillPostTitle(type = null) {
    setTimeout(function() {
        if(type) {
            $('#ask-your-question-modal').removeClass('js_enable_question_in_title');
            $('.posts-modal').text('Share a Story');
            $('#modal-post-title-label').text('Provide a title');
            $('#modal-post-desc-label').text('Write the content below');
            $('#postTitle').attr('placeholder', 'Magbigay ng titulo.');
            $('.mce-edit-area').find('label').text('Isulat dito ang nilalaman ng artikulo.');
            $('#post-type').val('article');
        } else {
            $('#ask-your-question-modal').addClass('js_enable_question_in_title');
            $('.posts-modal').text('Ask a Question');
            $('#modal-post-title-label').text('Ano ang tanong mo?');
            $('#modal-post-desc-label').text('Magdagdag ng ibang detalye');
            $('#postTitle').attr('placeholder', 'Simulan ng "Ano", "Paano", "Bakit", atbp.');
            $('.mce-edit-area').find('label').text('Magbigay ng impormasyon, picture, at iba pa');
            $('#post-type').val('question');
        }
    }, 100);
}

/**
 * send question like request
 * @param question_id
 * @param type
 */
function likeQuestion(question_id, type){
    var data = {
        question_id : question_id,
        type : type
    };
    loadAsync('posts/likeQuestion', data);
};

/**
 * show/hide question likes count
 * @param data
 */
function showQuestionLikes(data){
    if(!$('div').is('#questionLikes')){
        $('.post-text').append('<div class="hidden gray-text post__question-likes" id="questionLikes"><span id="questionLikesCount">0</span> person likes this</div>');
    }
    if(data.points > 0) {
        $('#questionLikes').removeClass('hidden');
    } else {
        $('#questionLikes').addClass('hidden');
    }
    $('#questionLikesCount').text(data.points);
    if(data.actionType == 'like'){
        $('#likeQuestionButton').text('UnRecommend');
        $('#likeQuestionButton').attr('onclick', 'likeQuestion(' + data.question_id + ', "unlike");')
    }
    if(data.actionType == 'unlike'){
        $('#likeQuestionButton').text('Recommend');
        $('#likeQuestionButton').attr('onclick', 'likeQuestion(' + data.question_id + ', "like");')
    }
};

/**
 * send report abuse
 */
$('#reportAbuseForm').on('submit', function(e){
    e.preventDefault();
    loadAsync('posts/saveReportAbuse', $(this).serialize());
});

/**
 * Send post answer
 */
$('#questionAnswerForm').on('submit', function(e){
    e.preventDefault();
    var $this = $(this);
    var $textarea = $this.find('[name=answer]');
    if ($textarea.val() == '') {
        showValidationError($textarea, 'Please enter your reply');
        return false;
    } else {
        if (typeof $this.find('[type=submit]').attr('data-islogged') != 'undefined') {
            var data = $this.serialize();
            $textarea.val('');
            $this.find('[type=submit]').addClass('reply-saving').text('Posting').attr('disabled', true);
            loadAsync('answers/saveAnswer', data);
        } else {
            var data = {
                formData: $this.serialize()
            }
            loadAsync('answers/store-guest-reply', data);
        }
    }
});


/**
 * process click on report, recommend and reply buttons in answer block
 */
$(document).on('click', '.comment-additional-button a', function(e){
    var $this = $(this);
    var answerId = parseInt($this.attr('data-id'));
    var action = $this.attr('data-action');

    if(typeof (action) != 'undefined'){
        var callback = action;
        window[callback](answerId);
    }

    e.preventDefault();


    //loadAsync('posts/saveAnswer', $(this).serialize());
});

/**
 * send recommend request
 * @param answerId
 */
function recommendAnswer(answerId) {
    loadAsync('answers/setRecommend', {answerId : answerId}); //->setUnrecommendAnswer
};

/**
 * send unrecommend request
 * @param answerId
 */
function unRecommendAnswer(answerId) {
    loadAsync('answers/setUnRecommend', {answerId : answerId});//->setRecommendAnswer
};

/**
 * process unreccomend request response
 * @param data
 */
function setUnrecommendAnswer(data) {
    var button = $('[data-id=' + data.id + '][data-action="recommendAnswer"]');
    if($('div').is('#likesMessage-' + data.id)){
        $('#likesMessage-' + data.id).remove();
    }
    if(data.points > 0) {
        button.parents('#answerActions-' + data.id).before('<div id="likesMessage-' + data.id + '" class="gray-text post__question-likes">' + data.points + ' person likes this</div>');
    }
    button.text('UnRecommend').attr('data-action', 'unRecommendAnswer');

};

/**
 * proccess reccomend request response
 * @param data
 */
function setRecommendAnswer(data) {
    var button = $('[data-id=' + data.id + '][data-action="unRecommendAnswer"]');
    if($('div').is('#likesMessage-' + data.id)){
        $('#likesMessage-' + data.id).remove();
    }
    if(data.points > 0) {
        button.parents('#answerActions-' + data.id).before('<div id="likesMessage-' + data.id + '" class="gray-text post__question-likes">' + data.points + ' person likes this</div>');
    }
    button.text('Recommend').attr('data-action', 'recommendAnswer');
};

/**
 * send request to show reply form
 * @param answerId
 */

function showReplyForm(answerId) {
    loadAsync('answers/showReplyForm', {answerId : answerId});
}

/**
 * process response and append reply form to answer
 * @param data
 */
function addReplyForm(data){
    var button = $('[data-id=' + data.id + '][data-action="showReplyForm"]');
    button.parents('#answerActions-' + data.id).parents('.answer-container').after(data.html);
};

/**
 * save reply
 */
$(document).on('submit', '#answerReplyForm', function(e){
    e.preventDefault();
    var $this = $(this);
    $this.find('[type=submit]').addClass('reply-saving').text('Posting').attr('disabled', true);
    loadAsync('answers/saveAnswerReply', $this.serialize());
});

/**
 * displays abuse report form
 * @param answerId
 */
function showReportAbuseForm(answerId) {
    loadAsync('answers/showReportAbuseForm', {answerId : answerId});
};

/**
 * send report abuse
 */
$(document).on('submit', '#answerReportAbuseForm', function(e){
    e.preventDefault();
    var $this = $(this);
    loadAsync('answers/saveAnswerReportAbuse', $this.serialize());
});


/**
 * initalze autocomplete for search input
 */
function initAutocompleteForSearch(){
    $("#searchInput").autocomplete({
        highlight : false,
        showHint: false,
        appendMethod:'replace',
        minLength: 2,
        limit: 10,
        timeoutSearch: 300,
        timeoutUpdate: 0,
        source:[
            {
                url:"/home/searchOptions?query=%QUERY%",
                type:'remote',
            },

        ],
        getValue:function(item){
            return item.split('<span')[0];
        },
        equal: function( value,query ){
            return true;
        },
        valid: function ( value,query ){
            return 1;
        }
    }).on('selected.xdsoft',function(e,datum){
        loadAsync('posts/to-post', {title: datum});
        // window.location.href = basePath + 'home/search?search=' + datum;
    });
};

/**
 * send category subscribe request
 * @param id
 */
function subscribeCategory(id){
    loadAsync('categories/subscribe', {id : id}); // ->changeSubscribeButton
}

/**
 * send category unsubcribe request
 * @param id
 */
function unsubscribeCategory(id){
    loadAsync('categories/unsubscribe', {id : id}); // ->changeSubscribeButton
}

/**
 * change subscription label after processing the request
 * @param data
 */
function changeSubscribeButton(data){
    var $this = $('#follow-link');
    $this.text(data.label);
    $this.attr('onclick', data.function);
}

/**
 * send question subscribe request
 * @param id
 */
function subscribeQuestionPost(id){
    loadAsync('posts/subscribe', {id : id})
}

/**
 * send question unsubscribe request
 * @param id
 */
function unsubscribeQuestionPost(id){
    loadAsync('posts/unsubscribe', {id : id})
}

/**
 * process question subscribe/unsubscribe request
 * @param data
 */
function changeSubscribeQuestionButton(data){
    var $this = $('#subscribeQuestion');
    $this.text(data.label);
    $this.attr('onclick', data.function);
}

$(window).on('hashchange', function() {
    if (window.location.hash) {
        var page = window.location.hash.replace('#', '');
        if (page == Number.NaN || page <= 0) {
            return false;
        } else {
            getPosts(page);
        }
    }
});

/**
 * proccess ajax pagination
 */
$(document).ready(function() {
    $(document).on('click', '.pagination a', function (e) {
        getPosts($(this).attr('href').split('page=')[1], $(this).attr('href').split('tab=')[1].replace('&page=' + $(this).attr('href').split('page=')[1], ''));
        e.preventDefault();
    });
});

/**
 * get post for particaular page
 * @param page
 * @param tab
 */
function getPosts(page, tab) {
    $.ajax({
        url : '?page=' + page,
        dataType: 'json',
        data : {
            tab : tab
        }
    }).done(function (data) {
        $('#' + tab).html(data);
        location.hash = page;
    });
}

/**
 * show big user avatar
 */
$(document).on('mouseenter', '.show-img-popup', function () {
    var $this = $(this);
    var pos = $this.position();

    var bigImg = $this.attr('src');
    if (typeof $this.attr('data-image') != 'undefined') {
        bigImg = $this.attr('data-image');
    }
    $('.img-large-hidden').attr('src', bigImg);
    $('.img-large-hidden').css({
        position : "absolute",
        top : pos.top + 150 + "px",
        left : pos.left + 110 + "px"
    }).show();
});

/**
 * hide big user avatar
 */
$(document).on('mouseleave', '.show-img-popup', function () {
    $('.img-large-hidden').hide();
});

/**
 * show registration big image
 */
$(document).on('mouseenter', '.register-description img', function () {
    var $this = $(this);
    var pos = $this.position();

    var bigImg = $this.attr('src');
    if (typeof $this.attr('data-image') != 'undefined') {
        bigImg = $this.attr('data-image');
    }
    $('.img-large-hidden').attr('src', bigImg);
    $('.img-large-hidden').css({
        position : "absolute",
        top : pos.top - 110 + "px",
        left : pos.left - 10 + "px"
    }).show();
});

/**
 * hide registration big image
 */
$(document).on('mouseleave', '.register-description img', function () {
    $('.img-large-hidden').hide();
});

/**
 * submit and validate login form
 */
$(document).on('submit', '#loginForm', function (event) {
    event.preventDefault();
    var $this = $(this);
    var email = $this.find('[name=email]');
    var password = $this.find('[name=password]');
    var submit = $this.find('[type=submit]');

    submit.text('Please wait');

    if (email.val() == ''){
        submit.text('Sign in');
        showValidationError(email, 'Please enter your email');

    }
    if (password.val() == ''){
        submit.text('Sign in');
        showValidationError(password, 'Please enter your password');
    }
    loadAsync('login-modal', $this.serialize());
});

/**
 * check email field
 */
$(document).on('keyup change', '#loginForm :input[name=email]', function () {
    var email = $(this);
    if (email.val() == ''){
        showValidationError(email, 'Please enter your email');
    }
});

/**
 * check password field
 */
$(document).on('keyup change', '#loginForm :input[name=password]', function () {
    var password = $(this);
    if (password.val() == ''){
        showValidationError(password, 'Please enter your password');
    }
});



/**
 * hide comment-form when login-form open
 */
$(document).on('click', '#comment-form__login', function () {
    $('#comment-modal').modal('hide');
    $('.modal__close').addClass('replyClose');
});

$(document).on('click', '.replyClose', function () {
    $('.modal__close').removeClass('replyClose');
    $('#comment-modal').modal('hide');
});


/**
 *  rotate caret-icon when dropdown-menu open
 */
$(document).on('click', '.dropdown-toggle', function (e) {
    e.preventDefault();
    $('.caret').toggleClass('caret--rotate');
});

$(document).on('click', function () {
    $('.caret').removeClass('caret--rotate');
});


$(document).on('change', '#postsForm :input', function () {
    checkPostFilling();
})

/**
 * checks if all post required fields are filled
 */
function checkPostFilling() {
    if ($('#postTitle').val() != '' && $('#parentCategorySelect').val() != '' && $('#subcategoriesSelect').val() != null && $('#subcategoriesSelect').val() != '' && !$('#postsForm').find('#postsSubmit').hasClass('saving')){
        $('#postsForm').find('#postsSubmit').removeClass('btn--grey-gradient').addClass('btn--green-gradient').removeAttr('disabled');
    } else {
        if (!$('#postsForm').find('#postsSubmit').hasClass('saving')) {
            $('#postsForm').find('#postsSubmit').addClass('btn--grey-gradient').removeClass('btn--green-gradient').attr('disabled', true);
        }
    }
}

$('#ask-your-question-modal').on('show.bs.modal', function (e) {
    checkPostFilling();
    initIcheck();
})

/**
 * close posts modal form after closing register modal form
 */
$(document).on('click', '.postsClose', function () {
    $(this).removeClass('postsClose');
    setTimeout(function () {
        $('#ask-your-question-modal').modal('show');
    }, 400);
});


/**
 * Show popover on hover and hide on click
 */
$(document).ready(function(){
    $("#recommendedBy").hover(function(){
        $("[data-toggle='popover']").popover('show');
    });
});

$(document).on('click', 'body', function () {
    $("[data-toggle='popover']").popover('hide');

});

$(document).on('click', "[data-toggle='popover']", function (e) {
    e.stopImmediatePropagation();
})

function closePostsModal(data) {
    $('#ask-your-question-modal').modal('hide');
    $('#postsForm')[0].reset();

    $('#postsSubmit').attr('disabled', true).addClass('btn--grey-gradient').removeClass('saving').text('Post');

    $('#subcategoriesSelect').addClass('hidden');
    $('#subSubcategoriesSelect').addClass('hidden');
    $('#parentCategorySelect').trigger('change');

    //loadAsync('categories/send-subscribers-notification', {data: data.question});
}

/**
 * Add alt attribute to the all page images using image name
 */
function addAltAttributeToImages(){
    $.each($('img'), function () {
        var $this = $(this);
        if (typeof $this.attr('alt') == 'undefined' || $this.attr('alt') == '') {
            var alt = $this.attr('src').split('/');
            alt = $(alt).get(-1);
            alt = alt.substr(0, alt.length - 4);
            $this.attr('alt', alt);
        }
    })
}


/**
 * Calculates the age of the user
 */
function initAgepicker() {
    $('.agepicker').datepicker({
        format: 'mm-dd-yyyy',
        autoclose: true,
        endDate: '-1d',
        startView: 2,
        defaultViewDate: {
            year: 1990
        }
    }).on('changeDate', function (e) {
        var chosenDate = new Date(e.date);
        var currentDate = new Date();
        var age = parseInt(currentDate.getFullYear()) - parseInt(chosenDate.getFullYear());

        if (currentDate.getMonth() <= chosenDate.getMonth() && currentDate.getDay() >= chosenDate.getDay()) {
            age = parseInt(currentDate.getFullYear()) - parseInt(chosenDate.getFullYear()) - 1;
        }

        var chosenBdMonth = chosenDate.getMonth() + 1;
        if (chosenBdMonth < 10) {
            chosenBdMonth = '0' + chosenBdMonth;
        }
        var chosenBdDay = chosenDate.getDate();
        if (chosenBdDay < 10) {
            chosenBdDay = '0' + chosenBdDay;
        }

        setTimeout(function () {
            $('.agepicker').val(age);
            $('.agepicker').attr('data-age', age);
            $('#user-bd').val(chosenDate.getFullYear() + '-' + chosenBdMonth + '-' + chosenBdDay)

        }, 100)
    });
}

$(document).on('focusout', '.agepicker', function () {
    var $this = $(this);
    if ($this.val() != '') {
        $this.val($this.attr('data-age'));
    }
});

/**
 * Validate medical history before submiting post
 */
$(document).on('click', '#postsSubmit:not(.js-store-quest-post)', function(e){
    e.preventDefault();
    if (checkRequired($('#medicalHistory'))) {
        $('#postsForm').submit();
    }
})

/**
 * Submit contact form
 */
$(document).on('submit', '#contactForm', function (event) {
    event.preventDefault();
    var $this = $(this);
    $this.find('input[type="submit"]').addClass('data-saving').attr('disabled', true).val('Sending');
    var data = $this.serialize();

    loadAsync('pages/saveFeedback', data);
});

/**
 * Display contact us form wit errors
 */
function showContactUsFormErrors() {
    initGoogleRecaptcha();

    $('#contactForm').find('input[type="submit"]').removeClass('data-saving').removeAttr('disabled').val('Send');
}

/**
 * Hide contact form after submiting
 */
function showFeedbackMessage() {
    $('#feedbackSuccess').removeClass('hidden');
    $('#feedbackContainer').addClass('hidden');
}

/**
 * Send email confirmation request to user
 * @param request
 */
function sendConfirmation(request)
{
    $('#signupForm').find('.js-finish-registration').removeClass('registering').text('Finish').removeAttr('disabled');
    loadAsync('send-confirmation', request.data);
}
function sendPostConfirmation(request)
{
    loadAsync('send-post-confirmation', request.data);
}

function sendExpertNotification(request)
{
    loadAsync('experts/send-notification', request.data);
}

/**
 * Show hello modal form
 * @param data
 */
function showHelloModal(data)
{
    $('#login-modal').modal('hide');

    $('#hello-modal').find('#helloContainer').html('Hello, ' + data.name);
    $('#hello-modal').modal('show');

    setTimeout(function () {
        location.reload();
    }, 500);
}

/**
 * Store guest post in session
 */
$(document).on('click', '.js-store-quest-post', function (e) {
    e.preventDefault();
    var $form = $(this).parents('form');
    var data = {
        form: $form.attr('id'),
        formData: $form.serialize()
    }

    if (checkRequired($('#medicalHistory'))) {
        $('#ask-your-question-modal').modal('hide');
        $('#comment-modal').modal('show');
        $('#comment-modal').find('.modal__close').addClass('postsClose');

        loadAsync('posts/store-guest-post', data);
    }
});


function displaySignUpModal(data){
    $('#ask-your-question-modal').modal('hide');
    $('#comment-modal').modal('show');
}


/**
 * Slide to reply and transform reply to green
 * @param data
 */
function goToReply(data) {
    initPopover();
    $('html, body').animate({
        scrollTop: $('[data-answer="' + data.newId + '"]').offset().top - 200
    }, 2000);

    $('#questionAnswerForm').find('[type=submit]').removeClass('reply-saving').text('Post reply').removeAttr('disabled');

    setTimeout(function () {
        $('[data-answer="' + data.newId + '"]').removeClass('post-answer--yellow');
    }, 3000);
}

function stopPropagation() {
    // setTimeout(function () {
        return false;
    // }, 1500)
}

$(document).on('click', '.js-mobile-show-search', function () {
    $('.js-main-search-header-form').removeClass('mobile-hide');
    $('.js-main-search-header-form-input').focus();
});

$(document).on('click', '.js-mobile-close-search', function () {
    $('.js-main-search-header-form').addClass('mobile-hide');
});

$(document).on('click', '.js-mobile-clear-search', function () {
    $('.js-main-search-header-form-input').val('');
});

$(document).on('keyup keydown change', '.js-main-search-header-form-input', function () {
    var $this = $(this);
    if ($this.val().length > 0) {
        $('.js-mobile-clear-search').removeClass('hidden');
    } else {
        $('.js-mobile-clear-search').addClass('hidden');
    }
});

// change breadcrumb width
$( document ).ready(function() {
    const breadcrumb = $('.breadcrumb');
    const mainContent = $('.main-content');
    if(breadcrumb.length) {
        changeBreadcrumbWidth();
        $(window).resize(function() {
            changeBreadcrumbWidth(breadcrumb)
        });
    }

    function changeBreadcrumbWidth() {
        const mainSiteContent = $('.main-site-content');
        breadcrumb.width( mainContent.width() );
        if($(window).width() > 767) {
            mainContent.css( "margin-top", breadcrumb.height() );
            mainSiteContent.css( "padding-top", 0 )
        } else {
            mainContent.css( "margin-top", 0 );
            mainSiteContent.css( "padding-top", '35px' )
        }
    }
});
//# change breadcrumb width

$('body').on('keyup', '.js_enable_question_in_title #postTitle', function () {
    var _titleLimit = 170;

    $(this).css('min-height', '0px').css('min-height', (this.scrollHeight) + 'px');

    let title = $('#postTitle').val().trim().split(' ').filter(word => word.length > 0);
    var titleLength = $('#postTitle').val().length;

    if(titleLength > _titleLimit){
        $('#postTitle').val($('#postTitle').val().substring(0, _titleLimit));
    }

    $('#postTitle-notification-content').text(titleLength);

    $(this).removeClass('form-warning').removeClass('form-danger');
    $('#postTitle-notification-content').removeClass('bg-warning').removeClass('bg-danger');
    $('#postTitle-notification-content').text('');

    // Fix Limit for last "?" letter
    _titleLimit = _titleLimit + 1;

    if(titleLength >= (_titleLimit - 10)){
        $(this).addClass('form-warning');
        $('#postTitle-notification-content').show();
        $('#postTitle-notification-content').addClass('bg-warning');
        $('#postTitle-notification-content').html('<strong>' + (_titleLimit - titleLength) + ' characters</strong> left. Magdagdag ng detalye sa baba.');
    }

    if(titleLength >= _titleLimit){
        $('#postTitle-notification-content').show();
        $(this).addClass('form-danger');
        $('#postTitle-notification-content').addClass('bg-danger');
        $('#postTitle-notification-content').html('<strong>0 characters</strong> left. Magdagdag ng detalye sa baba.');
    }

    if(titleLength < (_titleLimit - 10)){
        $('#postTitle-notification-content').hide();
    }

    let autocompleteContent = $('#autocomplete-content');

    if(title.length >= 3) {
        let content = '';
        let params = {
            "_token": $('meta[name="csrf-token"]').attr('content'),
            "title": title
        };

        $.ajax({
            type: 'GET',
            dataType: 'json',
            url: "/posts/search",
            data: params,
            success: function(data){
                $.each(data, function (key, value) {
                    content += `<p><a href='/${value.permalink}'">${value.title}</a></p>`;
                });
                autocompleteContent.html(`${content}`);
            }, error: function(){
                console.log('error');
            }
        });
    } else {
        autocompleteContent.empty();
    }
});

// Keep "?" on the end of post/question title
$('body').on('click change keyup keydown', '.js_enable_question_in_title #postTitle', function () {
    var titleLength = $('#postTitle').val().length;
    var lastChar = $('#postTitle').val().substr(-1);
    var currentPosition = $('#postTitle').get(0).selectionStart;

    if(lastChar !== '?'){
        $('#postTitle').val($('#postTitle').val() + '?');
    }

    if(currentPosition >= (titleLength - 1)){
        $('#postTitle').selectRange($('#postTitle').val().length - 1,$('#postTitle').val().length - 1);
    }
});

// Open Categories menu when open main menu
$(document).ready(function () {
    $('#main-menu-toggle-button').on('click', function () {
        setTimeout(function () {
            $('.js_categories_menu').addClass('open');
        }, 50);
        setTimeout(function () {
            $('.js_categories_menu').addClass('open');
        }, 500);
    });
});